import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/es6/promise'
import 'core-js/es6/symbol'
import 'core-js/es6/object'
import React from "react"
import mount from "./tools/mount"
import VkSdk from "@happysanta/vk-apps-sdk"
import store from "./store"
import {Provider} from "react-redux"
import DesktopContainer from "./containers/DesktopContainer/DesktopContainer"
import MobileContainer from "./containers/MobileContainer/MobileContainer"
import L from "./lang/L"
import "./style/index.css"
import Error from "./components/Error/Error"
import * as VkConnect from "@vkontakte/vkui-connect/index"
import {setClickInfo} from "./modules/ClickModule";

VkConnect.send("VKWebAppInit", {});

const defaultHeaderSettings = {"status_bar_style": "light", "action_bar_color": "#25274A"};
VkConnect.send("VKWebAppSetViewSettings", defaultHeaderSettings);

let startParams = VkSdk.getStartParams();
L.init(startParams.getLangCode()).then(() => {
	const platformClass = startParams.isMobile() && ~startParams.platform.indexOf('android') ? ' Tanos_android' : ' Tanos_ios';
	store.dispatch(setClickInfo());
	mount(
		<Provider store={store}>
			<div className={`Tanos_${startParams.isMobile() ? 'mobile' : 'desktop'}${platformClass}`}>
				{startParams.isMobile() ? <MobileContainer platform={startParams.platform}/> : <DesktopContainer/>}
			</div>
		</Provider>
	)
}).catch(e => {
	mount(<Error error={e}/>)
});
