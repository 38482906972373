import React, {Component} from "react"
import {connect} from "react-redux"
import "./CounterScreen.css"
import L from "../../lang/L";
import {nToBr} from "../../tools/helpers";
import VkSdk from "@happysanta/vk-apps-sdk";
import Button from "../Button/Button"
import WaitDots from "../WaitDots/WaitDots"
import SharingMenu from "../SharingMenu/SharingMenu"
import {CLICK_TIME,
	setClickInfo, changeStatusInGame, setBanningIsActive, setNotificationsEnabled, setUserAlreadyRefused
} from "../../modules/ClickModule";
import {vkConnectRequest} from "../../tools/VkConnectRequest";

class CounterScreen extends Component {

	state = {
		sharingMenuIsOpen: false,
		sharingMenuIsVisible: false,
	};

	constructor(props) {
		super(props);

		this.refuseGame = this.refuseGame.bind(this);
		this.openSharingMenu = this.openSharingMenu.bind(this);
		this.closeSharingMenu = this.closeSharingMenu.bind(this);
		this.hideSharingMenu = this.hideSharingMenu.bind(this);
	}

	componentWillMount() {
		this.setCounter();

		this.counter = setInterval(() => {this.setCounter()}, 6000)
	}

	componentDidMount() {
		if (!this.props.areNotificationsEnabled) {
			vkConnectRequest('VKWebAppAllowNotifications', {}, "VKWebAppAllowNotificationsResult", "VKWebAppAllowNotificationsFailed")
				.then((res) => {
					if (res.result) this.props.setNotificationsEnabled(true)
				})
		}
	}

	componentWillUnmount() {
		clearInterval(this.counter)
	}

	setCounter() {
		const date = Math.round((new Date())/1000),
			dateDiff = CLICK_TIME - date;

		if (dateDiff <= 0) {
			this.props.setClickInfo();
			this.props.updateTime();
			return
		}

		this.setState({
			days: Math.floor(dateDiff/86400),
			hours: Math.floor((dateDiff%86400)/3600),
			minutes: Math.ceil((dateDiff%3600)/60),
		});
	}

	openSharingMenu() {
		this.setState({sharingMenuIsOpen: true});
		setTimeout(() => {this.setState({sharingMenuIsVisible: true})},10)
	}

	closeSharingMenu() {
		const isMobile = VkSdk.getStartParams().isMobile();
		this.setState({sharingMenuIsVisible: false});
		if (isMobile) {
			setTimeout(() => {
				this.setState({sharingMenuIsOpen: false})
			}, 310)
		} else {
			this.setState({sharingMenuIsOpen: false})
		}
	}
	hideSharingMenu() {
		this.setState({sharingMenuIsVisible: false});
	}

	refuseGame() {
		this.props.setUserAlreadyRefused(true);
		this.props.changeStatusInGame('unknown')
	}

	renderActions() {
		const isMobile = VkSdk.getStartParams().isMobile();

		return (
			<div className="CounterScreen__actions">
				<div className="CounterScreen__button_wrap">
					<Button className="CounterScreen__button" type="color" onClick={this.openSharingMenu}>
						{L.t('button_share')}
						</Button>
					{!isMobile && this.state.sharingMenuIsOpen &&
						<SharingMenu status="member" isVisible={this.state.sharingMenuIsVisible}
											 closeMenu={this.closeSharingMenu} hideMenu={this.hideSharingMenu} />
					}
				</div>
				<Button className="CounterScreen__button"
								type="transparent"
								onClick={this.refuseGame}>
					{this.props.userStatusUpdating ? <WaitDots/> : L.t('button_refuse')}
				</Button>
			</div>
		)
	}

	renderTranslationButton() {
		return (
			<div className="CounterScreen__actions">
				<Button className="CounterScreen__button" type="color"
								href="https://vk.com/video-24136539_456239830" target="_blank" rel="noopener noreferrer">
					{L.t('button_watch_translation')}
				</Button>
			</div>
		)
	}

	render() {
		const {days, hours, minutes} = this.state,
			isMobile = VkSdk.getStartParams().isMobile();

		return (
			<div className="CounterScreen">
				<div className="CounterScreen__copyright">©MARVEL</div>
				<div className="CounterScreen__inner">
					<div className="CounterScreen__content">
						<div className="CounterScreen__title">
							{!!days && nToBr(L.t('counter_screen_days', {count: days}))} {!!hours && nToBr(L.t('counter_screen_hours', {count: hours}))} {!!minutes && nToBr(L.t('counter_screen_minutes', {count: minutes}))}
						</div>
						<div className="CounterScreen__info">
							<div className="CounterScreen__text">{L.t('counter_screen_text', {count: minutes})}</div>
						</div>
						{(days < 1 && hours < 1) ? this.renderTranslationButton() : this.renderActions()}
					</div>
				</div>
				{isMobile && this.state.sharingMenuIsOpen &&
					<SharingMenu status="member" isVisible={this.state.sharingMenuIsVisible}
											 closeMenu={this.closeSharingMenu} hideMenu={this.hideSharingMenu} />
				}
			</div>
		)
	}
}

function map(state) {
	return {
		userStatusUpdating: state.ClickModule.userStatusUpdating,
		areNotificationsEnabled: state.ClickModule.areNotificationsEnabled
	}
}

export default connect(map, {
	setClickInfo, changeStatusInGame, setBanningIsActive, setNotificationsEnabled, setUserAlreadyRefused
})(CounterScreen)
