import React, {Component} from "react"
import {connect} from "react-redux"
import "./FinalScreen.css"
import L from "../../lang/L"
import {nToBr} from "../../tools/helpers";
import VkSdk from "@happysanta/vk-apps-sdk";
import Button from "../Button/Button"
import SharingMenu from "../SharingMenu/SharingMenu"
import {BAN_PER_SEC, CLICK_TIME, setBanningIsActive} from "../../modules/ClickModule";

class FinalScreen extends Component {

	state = {
		disappearedCount: 0,
		survivorsCount: this.props.disappearedCount + this.props.survivorsCount,
		sharingMenuIsOpen: false,
		sharingMenuIsVisible: false
	};

	constructor(props) {
		super(props);

		this.setCounter = this.setCounter.bind(this);
		this.openSharingMenu = this.openSharingMenu.bind(this);
		this.closeSharingMenu = this.closeSharingMenu.bind(this);
		this.hideSharingMenu = this.hideSharingMenu.bind(this);
	}

	componentWillMount() {
		if (!this.props.banningIsActive) {
			this.setState({
				disappearedCount: this.props.disappearedCount,
				survivorsCount: this.props.survivorsCount,
			})
		} else {
			const secPassed = Math.round((new Date())/1000) - CLICK_TIME;

			this.setState({
				disappearedCount: secPassed * BAN_PER_SEC,
				survivorsCount: (this.props.disappearedCount + this.props.survivorsCount) - (secPassed * BAN_PER_SEC)
			});

			this.setCounter('disappeared', BAN_PER_SEC);
			this.setCounter('survivors', BAN_PER_SEC);
		}
	}

	componentWillUnmount() {
		clearInterval(this.disappearedCounter);
		clearInterval(this.survivorsCounter)
	}

	formatCount(count) {
		return nToBr(String(count).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1&nbsp;'));
	}

	setCounter(type, increasePerSec) {
		const min = increasePerSec - 10 < 0 ? increasePerSec : increasePerSec - 10,
			max = increasePerSec + 10;

		this[`${type}Counter`] = setInterval(() => {
			const dateNow = Math.round((new Date())/1000),
				banningIsActive = (dateNow - CLICK_TIME) < Math.round(this.props.disappearedCount/BAN_PER_SEC),
				finalCount = this.props[`${type}Count`],
				curCount = this.state[`${type}Count`];

			if (type === 'survivors' && curCount > finalCount && banningIsActive) {
				const newCount = curCount - this.getRand(min, max);
				this.setState({[`${type}Count`]: newCount > finalCount ? newCount : finalCount})
			} else if (type === 'disappeared' && curCount < finalCount && banningIsActive) {
				const newCount = curCount + this.getRand(min, max);
				this.setState({[`${type}Count`]: newCount < finalCount ? newCount : finalCount})
			} else {
				this.props.setBanningIsActive(false);
				clearInterval(this[`survivorsCounter`]);
				clearInterval(this[`disappearedCounter`]);
				this.setState({survivorsCount: this.props.survivorsCount, disappearedCount: this.props.disappearedCount})
			}
		}, 1000)
	}

	getRand(min, max) {
		let rand = min + Math.random() * (max + 1 - min);
		return Math.floor(rand);
	}

	openSharingMenu() {
		this.setState({sharingMenuIsOpen: true});
		setTimeout(() => {this.setState({sharingMenuIsVisible: true})},10)
	}

	closeSharingMenu() {
		const isMobile = VkSdk.getStartParams().isMobile();

		this.setState({sharingMenuIsVisible: false});
		if (isMobile) {
			setTimeout(() => {
				this.setState({sharingMenuIsOpen: false})
			}, 310)
		} else {
			this.setState({sharingMenuIsOpen: false})
		}
	}
	hideSharingMenu() {
		this.setState({sharingMenuIsVisible: false});
	}

	renderCount(type) {
		return (
			<div className="FinalScreen__part_inner">
				<div className="FinalScreen__count">{this.formatCount(this.state[`${type}Count`])}</div>
				<div className="FinalScreen__text">{nToBr(L.t(`final_screen_${type}`))}</div>
			</div>
		)
	}

	renderCountWithUser(type) {
		const isMobile = VkSdk.getStartParams().isMobile();

		return (
			<div className="FinalScreen__part_inner">
				<div className="FinalScreen__title">
					{L.t(`final_screen_${type}_you`)} {this.formatCount(this.state[`${type}Count`])} {nToBr(L.t(`final_screen_${type}`, {count: this.state[`${type}Count`]}))}
				</div>
				<div className="FinalScreen__button_wrap">
					<Button className="FinalScreen__button" type="color" onClick={this.openSharingMenu}>{L.t('button_share')}</Button>
					{!isMobile && this.state.sharingMenuIsOpen &&
						<SharingMenu status={this.props.userStatus} isVisible={this.state.sharingMenuIsVisible}
												 closeMenu={this.closeSharingMenu} hideMenu={this.hideSharingMenu} />
					}
				</div>
			</div>
		)
	}

	render() {
		const {userStatus, banningIsActive} = this.props,
			isMobile = VkSdk.getStartParams().isMobile();

		return (
			<div className="FinalScreen">
				<div className="FinalScreen__inner">
					<div className="FinalScreen__part FinalScreen__disappeared">
						{userStatus === 'banned' && !banningIsActive ?
							this.renderCountWithUser('disappeared') : this.renderCount('disappeared')
						}
						<div className={`FinalScreen__middle${banningIsActive ? ' FinalScreen__middle_translation' : ''}`}>
							{banningIsActive &&
								<Button className="FinalScreen__button" type="color" href="https://vk.com/video-24136539_456239830" target="_blank"
												rel="noopener noreferrer">
									{L.t('button_watch_translation')}
								</Button>
							}
						</div>
					</div>
					<div className="FinalScreen__part FinalScreen__survivors">
						{userStatus === 'alive' && !banningIsActive ?
							this.renderCountWithUser('survivors') : this.renderCount('survivors')
						}
					</div>
				</div>
				{isMobile && this.state.sharingMenuIsOpen &&
					<SharingMenu status={userStatus} isVisible={this.state.sharingMenuIsVisible}
											 closeMenu={this.closeSharingMenu} hideMenu={this.hideSharingMenu} />
				}
			</div>
		)
	}
}

function map(state) {
	return {
		userStatus: state.ClickModule.userStatus,
		disappearedCount: state.ClickModule.disappearedCount,
		survivorsCount: state.ClickModule.survivorsCount,
		banningIsActive: state.ClickModule.banningIsActive
	}
}

export default connect(map, {setBanningIsActive})(FinalScreen)
